import React, { Component } from 'react';
import '../styles/Legend.css';

class Legend extends Component {
    constructor(props) {
        super(props);
        this.state = {hidden:true};
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    handleToggleClick() {
        this.setState({hidden: !this.state.hidden});
    }

    render() {
        if (!this.state.hidden) {
            return (
                <div className='legend-wrapper' onClick={this.handleToggleClick}>
                    <div className='legend'>
                        <div className='legend-inner'>
                            <h3 className='legend-title'>Legend</h3>
                            <ul className='legend-items'>
                                <li className='legend-item'><i className='material-icons'>play_circle_outline</i> Start Timer</li>
                                <li className='legend-item'><i className='material-icons'>wb_sunny</i> Add to Day Queue</li>
                                <li className='legend-item'><i className='material-icons'>delete</i> Remove from List</li>
                                <li className='legend-item'><i className='material-icons'>alarm_add</i> Quick Log (15 min increments)</li>
                                <li className='legend-item'><i className='material-icons'>open_in_new</i> Open in TM</li>
                                <li className='legend-item'><i className='material-icons'>note_add</i> Add Note</li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return ( <a onClick={this.handleToggleClick} href='#' className='legend-toggle'>Legend</a> );
        }
    }
}

export default Legend;
