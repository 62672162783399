import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Cookie from 'universal-cookie';
import Autosuggest from 'react-autosuggest';
import {
    getCurrentEntry,
    stopEntry,
    startEntry,
    persistEntry,
    setEntryToLog,
    logTime,
    skipLogTime
} from '../actions/timeEntry';
import { addDayQueueItem } from '../actions/dayQueue';

import LogEntryForm from '../components/LogEntryForm';
import ReactInterval from 'react-interval';
import StartTimerButton from '../components/StartTimerButton';
import StopTimerButton from '../components/StopTimerButton';
import NotesToggle from '../components/NotesToggle';
import Timer from '../components/Timer';
import OmniSwitch from '../components/OmniSwitch';
import ApiService from '../services/apiService';
import moment from 'moment';
import 'moment-duration-format';

const cookie = new Cookie();
const getSuggestionValue = suggestion => suggestion;
const styleSuggestions = () => {
    let elements = document.getElementsByClassName('react-autosuggest__container');
    for (let i = 0; i < elements.length; i++) {
        let element = elements[i];
        let suggestionsContainer = element.children[1];
        let boundingRect = element.getBoundingClientRect();
        let width = boundingRect.width - 20;
        suggestionsContainer.style.width = width + 'px';
        let top = boundingRect.top + boundingRect.height;
        suggestionsContainer.style.top = top + 'px';
    }
};
const fetchSuggestions = (value, lookupPath, callback) => {
    ApiService.makeRequest(lookupPath, {
        q: value
    })
        .then(data => {
            callback(data);
        });
    styleSuggestions();
};
const renderSuggestion = suggestion => (
    <div>
        <span className="suggestion-value">{suggestion.value}</span>
    </div>
);

class TimerForm extends Component {
    constructor(props) {
        super(props);
        const notes = localStorage.getItem('notes');
        const omniCookie = cookie.get('omniSearch') == 'true' || false;
        this.state = {
            timerDidUpdate: false,
            showNotes: notes ? true : false,
            notes: notes,
            isLogging: false,
            logProgress: 0,
            accounts: [],
            projects: [],
            tasks: [],
            omniEnabled: omniCookie
        };
        this.timerForm = React.createRef();
        
        this.handleTimerToggle = this.handleTimerToggle.bind(this);
        this.timerUpdate = this.timerUpdate.bind(this);
        this.handleAddDayQueueItem = this.handleAddDayQueueItem.bind(this);
        this.handleLogTime = this.handleLogTime.bind(this);
        this.handleSetEntryToLog = this.handleSetEntryToLog.bind(this);
        this.handleSkipLogTime = this.handleSkipLogTime.bind(this);
        this.handleNotesToggle = this.handleNotesToggle.bind(this);
        this.handleOmniToggle = this.handleOmniToggle.bind(this);
        this.renderTaskSuggestion = this.renderTaskSuggestion.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getCurrentEntry());
    }

    static getDerivedStateFromProps(props, state) {
        if (state.timerDidUpdate) {
            return {
                timerDidUpdate: false
            };
        }

        const entryStart = props.currentEntry.entryStart;
        const isLogging = entryStart > 0;

        return {
            isLogging: isLogging,
            logProgress: isLogging ? Math.floor(Date.now() / 1000) - entryStart : 0,
            accountID: state.accountID || props.currentEntry.accountID || 0,
            accountName: state.accountName || props.currentEntry.accountName || '',
            projectID: state.projectID || props.currentEntry.projectID || 0,
            projectName: state.projectName || props.currentEntry.projectName || '',
            taskID: state.taskID || props.currentEntry.taskID || 0,
            taskName: state.taskName || props.currentEntry.taskName || ''
        };
    }

    handleAddDayQueueItem(id) {
        this.props.dispatch(addDayQueueItem(id));
    }

    handleSetEntryToLog(entryToLog) {
        this.props.dispatch(setEntryToLog(entryToLog));
    }

    handleLogTime() {
        this.props.dispatch(logTime());
    }

    handleSkipLogTime() {
        this.props.dispatch(skipLogTime());
    }

    timerUpdate() {
        this.setState({
            timerDidUpdate: true,
            logProgress: Math.floor(Date.now() / 1000) - this.props.currentEntry.entryStart
        });
    }

    handleTimerToggle(start) {
        if (start) {
            this.props.currentEntry.accountID = this.state.accountID;
            this.props.currentEntry.accountName = this.state.accountName;
            this.props.currentEntry.projectID = this.state.projectID;
            this.props.currentEntry.projectName = this.state.projectName;
            this.props.currentEntry.taskID = this.state.taskID;
            this.props.currentEntry.taskName = this.state.taskName;
            this.props.dispatch(startEntry(this.props.currentEntry));
            return;
        }

        this.props.dispatch(stopEntry(this.props.currentEntry.entryID))
            .then(() => {
                this.setState({
                    notes: '',
                    showNotes: false,
                    accountID: 0,
                    projectID: 0,
                    taskID: 0,
                    accountName: '',
                    projectName: '',
                    taskName: '',
                });
                localStorage.removeItem('notes');
            });
    }

    handleNotesToggle() {
        this.setState({showNotes: !this.state.showNotes});
    }

    handleOmniToggle() {
        const omniEnabled = !this.state.omniEnabled;
        // remove project, account and task states if we are going to the omnisearch mode
        if(omniEnabled) {
            this.setState({
                projectID: 0,
                projectName: '',
                accountID: 0,
                accountName: ''
            });
        } else {
            this.setState({
                anyTaskID: 0,
                anyTaskName:''
            });
        }

        cookie.set('omniSearch', omniEnabled, {
            maxAge: 31557600,
            path: '/'
        });

        this.setState({ omniEnabled: omniEnabled });
    }

    handleOnBlur () {
        this.props.dispatch(persistEntry(this.props.currentEntry));
    }

    renderTaskSuggestion (suggestion) {
        const id = `open-task-${suggestion.id}`;
        const href = `${process.env.ACRONETURL}/projectManager.ami?action=manageTask&workingTaskID=${suggestion.id}`;
        const showProgress = suggestion.actualWork && suggestion.estimatedWork;
        return (
            <div>
                <span className="suggestion-value">
                    {suggestion.value}
                </span>
                {' '}
                <span className="task-add-day-queue" onClick={() => { this.handleAddDayQueueItem(suggestion.id); }}>
                    <i className="material-icons grey">wb_sunny</i>
                </span>
                {' '}
                <span id={id}>
                    <a rel="noopener noreferrer" target="_blank" href={href}>
                        <i className="material-icons grey">open_in_new</i>
                    </a>
                </span>
                {
                    showProgress && (
                        <div className='progress'>
                            <div className='task-progress-bar'>
                                <div className='task-progress'
                                    style={{ width: `${(suggestion.actualWork / suggestion.estimatedWork) * 100}%`, padding: 0 }}
                                ></div>
                            </div>
                            {`${((suggestion.actualWork / suggestion.estimatedWork) * 100).toFixed(2)}%`}: {`${suggestion.actualWork} / ${suggestion.estimatedWork} hours`}
                        </div>
                    )
                }
            </div>
        );
    }

    render() {
        let toggleButton, logForm, notes, timer = null;
        let canLogTime = !(this.props.currentEntry.taskName === '') || !(this.props.currentEntry.anyTaskName === '');

        if (!this.state.isLogging) {
            toggleButton =
                <StartTimerButton handleTimerToggle={this.handleTimerToggle} disabled={!canLogTime}/>;
            document.title = 'Have you logged your time today?';
        }
        else {
            toggleButton =
                <StopTimerButton handleTimerToggle={this.handleTimerToggle} disabled={!canLogTime} />;
            timer = <Timer progress={this.state.logProgress} timeOverall={this.props.currentEntry.estimatedWork} timeAlreadySpent={this.props.currentEntry.actualWork} />;
            document.title = moment.duration(this.state.logProgress, 'seconds').format('hh:mm:ss', { trim: false });
        }

        if (this.props.timerData.entryToLog) {
            if (typeof this.props.timerData.entryToLog.notes === 'undefined') {
                this.props.timerData.entryToLog.notes = this.state.notes;
            }

            const actions = {
                logTime: this.handleLogTime,
                setEntryToLog: this.handleSetEntryToLog,
                skipLogTime: this.handleSkipLogTime
            };
            logForm = <LogEntryForm actions={actions} entry={this.props.timerData.entryToLog}/>;
        }

        if (this.state.showNotes) {
            notes =
                <textarea
                    className="timer-notes" 
                    onChange={(event) => {
                        let inputValue = event.target.value;
                        localStorage.setItem('notes', inputValue);
                        this.setState({notes: inputValue});
                    }}
                    value={this.state.notes}
                    placeholder="Notes added here will be appended to the task"
                    name="form[notes]"
                    id="form_notes">
                </textarea>;
        }

        return (
            <div id="timer" className="timer">
                <div id="timer-logo"></div>
                {timer}
                <form className="timer_form" ref={this.timerForm}>
                    <div className="timer-form-items">
                        <ReactInterval timeout={200} enabled={this.state.isLogging} callback={this.timerUpdate}/>

                        <OmniSwitch enabled={this.state.omniEnabled} handleOmniToggle={this.handleOmniToggle}></OmniSwitch>
                        <div className='timer-form-inputs'>
                            <span className={'react-autosuggest' + (this.state.omniEnabled ? '--disabled':'')}>
                                <Autosuggest
                                    suggestions={this.state.accounts}
                                    onSuggestionsFetchRequested={({ value }) => {
                                        const lookupPath = '/request/lookup/account';
                                        const callback = (data) => {
                                            this.setState({
                                                accounts: data
                                            });
                                        };
                                        fetchSuggestions(value, lookupPath, callback);
                                    }}
                                    onSuggestionsClearRequested={() => {
                                        this.setState({
                                            accounts: []
                                        });
                                    }}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    shouldRenderSuggestions={()=>true}
                                    focusInputOnSuggestionClick={false}
                                    inputProps={{
                                        placeholder: 'Accounts',
                                        value: this.state.accountName,
                                        onChange: (event, { newValue }) => {
                                            this.setState({
                                                accountID: newValue.id ?? 0,
                                                accountName: newValue.value ?? newValue
                                            });
                                        }
                                    }}
                                />
                            </span>

                            <span className={'react-autosuggest' + (this.state.omniEnabled ? '--disabled':'')}>
                                <Autosuggest
                                    suggestions={this.state.projects}
                                    onSuggestionsFetchRequested={({ value }) => {
                                        const lookupPath = `/request/lookup/project/${this.state.accountID}`;
                                        const callback = (data) => {
                                            this.setState({
                                                projects: data
                                            });
                                        };
                                        fetchSuggestions(value, lookupPath, callback);
                                    }}
                                    onSuggestionsClearRequested={() => {
                                        this.setState({
                                            projects: []
                                        });
                                    }}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    shouldRenderSuggestions={()=>true}
                                    focusInputOnSuggestionClick={false}
                                    inputProps={{
                                        placeholder: 'Projects',
                                        value: this.state.projectName,
                                        onChange: (event, { newValue }) => {
                                            this.setState({
                                                projectID: newValue.id ?? 0,
                                                projectName: newValue.value ?? newValue
                                            });
                                        }
                                    }}
                                />
                            </span>

                            <span className={'react-autosuggest task-results' + (this.state.omniEnabled ? '--omni':'')}>
                                <Autosuggest
                                    suggestions={this.state.tasks}
                                    onSuggestionsFetchRequested={({ value }) => {
                                        const lookupPath = `/request/lookup/task/${this.state.accountID}/${this.state.projectID}`;
                                        const callback = (data) => {
                                            this.setState({
                                                tasks: data
                                            });
                                        };
                                        fetchSuggestions(value, lookupPath, callback);
                                    }}
                                    onSuggestionsClearRequested={() => {
                                        this.setState({
                                            tasks: []
                                        });
                                    }}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={this.renderTaskSuggestion}
                                    shouldRenderSuggestions={()=>true}
                                    focusInputOnSuggestionClick={false}
                                    inputProps={{
                                        placeholder: 'Tasks',
                                        value: this.state.taskName,
                                        onChange: (event, { newValue }) => {
                                            this.setState({
                                                taskID: newValue.id ?? 0,
                                                taskName: newValue.value ?? newValue
                                            });
                                        }
                                    }}
                                />
                            </span>
                        </div>
                        {notes}

                        <input type="hidden" id="projectID" name="projectID" value={this.state.projectID}/>
                        <input type="hidden" id="accountID" name="accountID" value={this.state.accountID}/>
                        <input type="hidden" id="taskID" name="taskID" value={this.state.taskID}/>
                    </div>
                    <div className="timer-form-actions">
                        {toggleButton}
                        <NotesToggle parentCallback={this.handleNotesToggle}/>
                    </div>
                    {logForm}
                </form>
            </div>
        );

    }
}

TimerForm.propTypes = {
    currentEntry: PropTypes.object.isRequired,
    dayQueue: PropTypes.array.isRequired,
    timerData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { currentEntry, dayQueue, timerData } = state;

    return {
        currentEntry,
        dayQueue,
        timerData
    };
}

export default connect(mapStateToProps)(TimerForm);
