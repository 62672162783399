import $ from 'jquery';

class ApiService {
    makeRequest(path, data = null) {
        let method = 'GET';
        if (data !== null) {
            method = 'POST';
        }

        return new Promise((resolve) => {
            $.ajax({
                method,
                xhrFields: {
                    withCredentials: true
                },
                /* eslint-disable no-undef */
                url: process.env.BASEPATH + path,
                data: data,
                statusCode: {
                    403: () => {
                        /* eslint-disable no-undef */
                        window.location.href = `${process.env.ACRONETURL}?returnURL=${window.location}`;
                    },
                    500: () => {
                        alert('Request to API returned 500 Error');
                    }
                }
            })
                .then(response => {
                    resolve(response);
                })
                .catch((response) => {
                    let message = '';

                    if (response.responseJSON.errors) {
                        message = 'Error:\n';

                        for (let i = 0; i < response.responseJSON.errors.length; i++) {
                            message += response.responseJSON.errors[i] + '\n';
                        }
                    }
                    else {
                        message = 'Unknown error.';
                    }

                    alert(message);
                });
        });
    }
}

export default (new ApiService());
