import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OmniSwitch extends Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        this.props.handleOmniToggle();
    }

    render() {
        var classes = 'btn-omni-switch';
        var omniClass = 'omni';
        var classicClass = 'omni';

        if(!this.props.enabled) {
            omniClass += ' disabled';
            classicClass = 'omni';
        } else {
            omniClass = 'omni';
            classicClass += ' disabled';
        }

        return (
            <a className={classes} onClick={this.handleOnClick} href="#" title="Switch from Omni to Classic">
                <p className={omniClass}>omni</p>
                <p className={classicClass}>classic</p>
            </a>
        );
    }
}

OmniSwitch.propTypes = {
    enabled: PropTypes.bool.isRequired,
    handleOmniToggle: PropTypes.func.isRequired
};

export default OmniSwitch;
