import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MessageBox extends Component {
    render() {
        return (
            <div id="messages" className="messages">
                <MessageList messages={this.props.messages}/>
            </div>
        );
    }
}

MessageBox.propTypes = {
    messages: PropTypes.array.isRequired
};

class MessageList extends Component {
    render() {
        var messageNodes = this.props.messages.map(function (message) {
            return (
                <Message type={message.type} key={message.id}>
                    {message.text}
                </Message>
            );
        });

        return (
            <div className="messageList">
                {messageNodes}
            </div>
        );
    }
}

MessageList.propTypes = {
    messages: PropTypes.array.isRequired,
};

class Message extends Component {
    render() {
        var className = 'message ' + this.props.type;

        return (
            <div className={className}>{this.props.children}</div>
        );
    }
}

Message.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
};

export {MessageBox, MessageList, Message};
