import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StopTimerButton extends Component {
    constructor(props) {
        super(props);
        
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        if (!this.props.disabled) {
            this.props.handleTimerToggle(false);
        }
    }

    render() {
        return (
            <a onClick={this.handleOnClick} href="#" className="btn-stop-timer material-icons" title="Stop Timer">pause_circle_outline</a>
        );
    }
}

StopTimerButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    handleTimerToggle: PropTypes.func.isRequired,
};

export default StopTimerButton;
