import * as types from '../constants/actionTypes';
import ApiService from '../services/apiService';

function receiveCurrentEntry(json) {
    return {
        type: types.RECEIVE_CURRENT_ENTRY,
        entry: json
    };
}

export function getCurrentEntry() {
    return (dispatch) => {
        return ApiService.makeRequest('/request/current-entry')
            .then(json => dispatch(receiveCurrentEntry(json)));
    };
}

function receiveStartEntry(json) {
    return {
        type: types.RECEIVE_START_ENTRY,
        entry: json
    };
}

export function startEntry(entry) {
    return (dispatch) => {
        return ApiService.makeRequest('/request/start-entry', {
            entry
        })
            .then(json => dispatch(receiveStartEntry(json)));
    };
}

function receiveStopEntry(json) {
    return {
        type: types.RECEIVE_STOP_ENTRY,
        data: json
    };
}

export function stopEntry(entryID) {
    return (dispatch) => {
        return ApiService.makeRequest('/request/stop-entry', {
            entryID
        })
            .then(json => dispatch(receiveStopEntry(json)));
    };
}

function receivePersistEntry(json) {
    return {
        type: types.RECEIVE_PERSIST_ENTRY,
        entry: json
    };
}

function noPersistEntry(entry) {
    return {
        type: types.NO_PERSIST_ENTRY,
        entry
    };
}

export function persistEntry(entry) {
    return (dispatch) => {
        if (entry.entryID) {
            return ApiService.makeRequest('/request/update-entry', {
                entry
            })
                .then(json => dispatch(receivePersistEntry(json)));
        }
        else {
            dispatch(noPersistEntry(entry));
        }
    };
}

export function setEntryToLog(entryToLog) {
    return {
        type: types.SET_ENTRY_TO_LOG,
        entryToLog
    };
}

function receiveLogTime(json) {
    return {
        type: types.RECEIVE_LOG_TIME,
        activityList: json.activityList
    };
}

export function logTime(entryToLog) {
    return (dispatch, getState) => {
        const state = getState();

        let entry = state.timerData.entryToLog;

        // The API requires that we pass along notes.
        // If a note entry wasn't made, set it to an empty string and let the API handle it.
        if (typeof entry.notes === 'undefined') {
            entry.notes = '';
        }

        if (entryToLog !== null) {
            ApiService.makeRequest('/request/log-time', {
                entryToLog: entry
            })
                .then(json => dispatch(receiveLogTime(json)));
        }
    };
}

export function skipLogTime() {
    return {
        type: types.SKIP_LOG_TIME
    };
}
