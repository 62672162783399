import * as types from '../constants/actionTypes';
import ApiService from '../services/apiService';

function requestActivityList(day) {
    return {
        type: types.REQUEST_ACTIVITY_LIST,
        day
    };
}

function receiveActivityList(json, day) {
    return {
        type: types.RECEIVE_ACTIVITY_LIST,
        activityList: json.activityList,
        day
    };
}

function fetchActivityList(day) {
    return dispatch => {
        dispatch(requestActivityList(day));

        return ApiService.makeRequest('/request/recently-logged', { day })
            .then(json => dispatch(receiveActivityList(json, day)));
    };
}

export function getActivityList(day = 'today') {
    return (dispatch) => {
        return dispatch(fetchActivityList(day));
    };
}

function receiveRemoveTasklessTime(response, originalState) {
    return {
        type: types.RECEIVE_REMOVE_TASKLESS_TIME,
        result: response.result,
        originalState
    };
}

function requestRemoveTasklessTime(entryIds) {
    return {
        type: types.REQUEST_REMOVE_TASKLESS_TIME,
        entryIds
    };
}

export function removeTasklessTime(entryIds) {
    return (dispatch, getState) => {
        const { activityList } = getState();
        dispatch(requestRemoveTasklessTime(entryIds));

        return ApiService.makeRequest('/request/remove-taskless-time', { entryIds })
            .then(response => dispatch(receiveRemoveTasklessTime(response, activityList)));
    };
}

export function setActivityList() {
    return {
        type: types.SET_ACTIVITY_LIST
    };
}
