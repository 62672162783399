import React, { Component } from 'react';
import Legend from '../components/Legend';

class Links extends Component {
    render() {
        return ( 
            <div className="links">
                <Legend/>
                 &nbsp;|&nbsp; 
                <a className="link" target="_blank" rel="noopener noreferrer" href="https://wiki.acromediainc.com/wiki/Task_Time_Management">Task Wiki</a>
            </div>    
        );
    }
}

export default Links;
