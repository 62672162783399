import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayQueueRemoveButton from './DayQueueRemoveButton';

class DayQueueItem extends Component{
    constructor(props) {
        super(props);

        this.removeItem = this.removeItem.bind(this);
        this.startItem = this.startItem.bind(this);
    }

    removeItem() {
        this.props.removeDayQueueItem(this.props.item);
    }

    startItem() {
        this.props.startEntry(this.props.item);
    }

    render() {
        var id = 'item_' + this.props.item.itemID;

        let taskManagerUrl = 'https://acronet.acromedia.com/projectManager.ami?action=manageTask&workingWebAccountID=1196&workingProjectID=' + this.props.item.projectID + '&workingTaskID=' + this.props.item.taskID;

        return (
            <div id={id} className='day-queue-item'>
                <span {...this.props.dragHandleProps} data-tip data-for={'day-queue-handle-' + id} className="handle"><i className="material-icons acro-orange">open_with</i></span>
                <span >
                    <a  style={{ width: '60%', overflow: 'hidden' }}
                        data-tip
                        data-for={'day-queue-start-' + this.props.item.itemID}
                        onClick={() => this.startItem()}
                        href="#"
                    >{this.props.item.taskName} - {this.props.item.accountName}</a>
                </span>
                <DayQueueRemoveButton onClick={this.removeItem} item={this.props.item}/>
                <span className="open-task" data-tip data-for={'day-queue-open-task-' + id}><a href={taskManagerUrl} target="_blank" rel="noopener noreferrer"><i className="material-icons">open_in_new</i></a></span>
            </div>
        );
    }
}

DayQueueItem.propTypes = {
    item: PropTypes.object.isRequired,
    removeDayQueueItem: PropTypes.func.isRequired,
    startEntry: PropTypes.func.isRequired,
    dragHandleProps: PropTypes.object.isRequired,
};

export default DayQueueItem;
