import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StartTimerButton extends Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false};

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        if (!this.props.disabled && !this.state.clicked) {
            this.props.handleTimerToggle(true);
            this.setState({ clicked: true });
        }
    }

    render() {
        var classes = 'btn-start-timer material-icons';

        if (this.props.disabled) {
            classes += ' disabled';
        }

        return (
            <a className={classes} onClick={this.handleOnClick} href="#" title="Start Timer">play_circle_outline</a>
        );
    }
}

StartTimerButton.propTypes = {
    disabled: PropTypes.bool.isRequired,
    handleTimerToggle: PropTypes.func.isRequired
};

export default StartTimerButton;
