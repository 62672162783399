import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TasklessTime from '../components/TasklessTime';
import LoggedTime from '../components/LoggedTime';
import ActivityListMenu from '../components/ActivityListMenu';

import { getActivityList, removeTasklessTime } from '../actions/activityList';
import { addDayQueueItem } from '../actions/dayQueue';
import { startEntry } from '../actions/timeEntry';

export class ActivityList extends Component {

    constructor(props) {
        super(props);
        this.handleAddDayQueueItem = this.handleAddDayQueueItem.bind(this);
        this.handleStartEntry = this.handleStartEntry.bind(this);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleRemoveTasklessTime = this.handleRemoveTasklessTime.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getActivityList());
    }

    handleAddDayQueueItem(item) {
        this.props.dispatch(addDayQueueItem(item));
    }

    handleStartEntry(item) {
        this.props.dispatch(startEntry(item));
    }

    handleDayClick(day) {
        this.props.dispatch(getActivityList(day));
    }

    handleRemoveTasklessTime(entryIds) {
        this.props.dispatch(removeTasklessTime(entryIds));
    }

    render() {
        var _this = this;
        const currentlyLogging = (this.props.currentEntry.entryID > 0);
        const dayText = this.props.activityList.day === 'today' ? 'Today' : 'Yesterday';
        let totalLogged = 0;

        var withTaskNodes = this.props.activityList.withTask.map(function (loggedTime) {
            totalLogged += parseFloat(loggedTime.amount);

            return (
                <LoggedTime startEntry={_this.handleStartEntry} currentlyLogging={currentlyLogging} addDayQueueItem={_this.handleAddDayQueueItem} key={loggedTime.taskID} item={loggedTime} />
            );
        });

        let totalLoggedText = `${totalLogged} hours`;

        var noTaskNodes = this.props.activityList.noTask.map(tasklessTime => {
            return (
                <TasklessTime
                    remove={this.handleRemoveTasklessTime}
                    entryIds={tasklessTime.entryIds}
                    key={tasklessTime.entryIds[0]}
                    amount={tasklessTime.formattedAmount}
                    taskName={tasklessTime.taskName} />
            );
        });

        let timeWarning = null;

        if (this.props.activityList.day === 'yesterday' && totalLogged > 0 && totalLogged < 7) {
            const message = `Whoa, you only logged ${totalLoggedText} yesterday!`;

            timeWarning = (
                <span className="time-warning">
                    <i className="material-icons acro-orange">report_problem</i> {message}
                </span>
            );
        }

        return (
            <div id="activity" className="timer_section">
                <div className="title">
                    <i className="material-icons icon">access_time</i>
          Recently Logged
                </div>
                <div className="content ui-helper-clearfix">
                    <div className="with-tasks task-list">
                        <h4 className="heading">{dayText}: <span>{totalLoggedText}</span>
                        </h4>
                        <div className="list">
                            {withTaskNodes}
                        </div>
                    </div>
                    <div className="no-tasks task-list">
                        <h4 className="heading">Unassigned</h4>
                        <div className="list">
                            {noTaskNodes}
                        </div>
                    </div>
                    <ActivityListMenu day={this.props.activityList.day} onClick={this.handleDayClick} />
                    {timeWarning}
                </div>
            </div>
        );
    }
}

ActivityList.propTypes = {
    activityList: PropTypes.object.isRequired,
    dayQueue: PropTypes.array.isRequired,
    currentEntry: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { activityList, dayQueue, currentEntry } = state;

    return {
        activityList,
        dayQueue,
        currentEntry
    };
}

export default connect(mapStateToProps)(ActivityList);
