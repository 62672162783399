import React from 'react';
import PropTypes from 'prop-types';

const ActivityListMenu = ({ day, onClick }) => {
    let dayLinkText;

    function handleOnClick(e) {
        e.preventDefault();
        const newDay = day === 'yesterday' ? 'today' : 'yesterday';
        onClick(newDay);
    }

    if (day === 'today') {
        dayLinkText = '< Yesterday';
    }
    else {
        dayLinkText = 'Today >';
    }

    return (
        <div className="activity-list-menu">
            <a href="#" className="activity-list-menu__toggle" onClick={handleOnClick}>{dayLinkText}</a>
        </div>
    );
};

ActivityListMenu.propTypes = {
    day: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default ActivityListMenu;
