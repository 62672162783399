import React, { Component } from 'react';
import '../styles/normalize.css';
import '../styles/App.css';
import '../../node_modules/jquery-ui/themes/base/all.css';

// Components
import { MessageBox } from '../components/MessageBox';
import Links from './Links';
import TimerForm from './TimerForm';
import DayQueue from './DayQueue';
import ActivityList from './ActivityList';
import ThemeSwitcher from './ThemeSwitcher';
import BgSwitcher from './BgSwitcher';

class App extends Component {
    render() {
        const messages = [];

        return (
            <div id="main">
                <div className="timer_app">
                    <MessageBox messages={messages} />
                    <TimerForm />
                    <DayQueue />
                    <ActivityList />
                    <div className="appearance-setting">
                        <Links/>
                        <BgSwitcher />
                        <ThemeSwitcher />
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
