import React, { Component } from 'react';
import PropTypes from 'prop-types';

class NotesToggle extends Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }
    
    handleOnClick(e) {
        this.props.parentCallback();
        e.preventDefault();
    }

    render() {
        return (
            <a onClick={this.handleOnClick} href='#' className='notes-toggle material-icons'>note_add</a>
        );
    }
}

NotesToggle.propTypes = {
    parentCallback: PropTypes.func.isRequired
};

export default NotesToggle;
