import React from 'react';
import PropTypes from 'prop-types';
import DayQueueAddButton from './DayQueueAddButton';
import classNames from 'classnames';

const LoggedTime = ({ item, addDayQueueItem, currentlyLogging, startEntry }) => {
    var dayQueueButton = '';

    function handleAddDayQueueItem() {
        addDayQueueItem(item.taskID);
    }

    function handleOnClick() {
        startEntry(item);
    }

    if (!item.inDayQueue) {
        dayQueueButton = (
            <DayQueueAddButton addDayQueueItem={handleAddDayQueueItem} />
        );
    }

    let containerClasses = classNames({
        'logged-time': true,
        'can-log': !currentlyLogging
    });

    let taskManagerUrl = 'https://acronet.acromedia.com/projectManager.ami?action=manageTask&workingWebAccountID=1196&workingProjectID=' + item.projectID + '&workingTaskID=' + item.taskID;

    return (
        <div data-tip data-for={'logged-time-' + item.taskID} className={containerClasses}>
            <div>
                <span className="amount">{item.formattedAmount}</span>
        &nbsp;- <span className="startEntry" onClick={handleOnClick}>{item.taskName}</span> - {item.accountName} &nbsp;
                {dayQueueButton} - <a href={taskManagerUrl} target="_blank" rel="noopener noreferrer"><i className="material-icons">open_in_new</i></a>
            </div>
        </div>
    );
};

LoggedTime.propTypes = {
    item: PropTypes.object.isRequired,
    addDayQueueItem: PropTypes.func.isRequired,
    startEntry: PropTypes.func.isRequired,
    currentlyLogging: PropTypes.bool.isRequired
};

export default LoggedTime;
