import React, { Component } from 'react';
import Cookie from 'universal-cookie';
const cookie = new Cookie();

class ThemeSwitcher extends Component {

    constructor(props) {
        super(props);

        this.state = {
            theme: this.getThemePreference()
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        if (event.target.value === 'light') {
            document.body.classList.add('body--light');

            this.setState({
                theme: 'light'
            });

            this.setThemePreference('light');
        }
        else {
            document.body.classList.remove('body--light');

            this.setState({
                theme: 'dark'
            });

            this.setThemePreference('dark');
        }
    }

    getThemePreference() {
        let theme = cookie.get('theme');

        return theme === undefined ? 'dark' : theme;
    }

    setThemePreference(theme) {
        cookie.set('theme', theme, {
            maxAge: 31557600,
            path: ''
        });

        return true;
    }

    componentDidMount() {
        if (this.state.theme === 'light') {
            document.body.classList.add('body--light');
        }
        else {
            document.body.classList.remove('body--light');
        }
    }

    render() {
        return (
            <div className="theme-switcher">
                <label>Theme</label>
                <select onChange={this.handleChange} value={this.state.theme}>
                    <option value="dark">Dark</option>
                    <option value="light">Light</option>
                </select>
            </div>
        );
    }
}

export default ThemeSwitcher;
