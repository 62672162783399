import React from 'react';
import PropTypes from 'prop-types';

const TasklessTime = ({ amount, taskName, remove, entryIds }) => {
    function handleOnClick(e) {
        remove(entryIds);
        e.preventDefault();
    }

    return (
        <div className="taskless-time">
            {amount} - {taskName}
            <a href="#" onClick={handleOnClick} className="remove"><i className="material-icons">remove_circle_outline</i></a>
        </div>
    );
};

TasklessTime.propTypes = {
    amount: PropTypes.string.isRequired,
    taskName: PropTypes.string.isRequired,
    remove: PropTypes.func.isRequired,
    entryIds: PropTypes.array.isRequired
};

export default TasklessTime;
