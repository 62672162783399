import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DayQueueRemoveButton extends Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick () {
        this.props.onClick(this.props.item.itemID);
    }

    render() {
        return (
            <span>
                <i data-tip data-for={'day-queue-remove-' + this.props.item.itemID} className="remove material-icons" onClick={this.handleOnClick}>remove_circle_outline</i>
            </span>
        );
    }
}

DayQueueRemoveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
};

export default DayQueueRemoveButton;
