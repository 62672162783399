import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-duration-format';

class Timer extends Component {

    render() {
        const formattedProgress = moment.duration(this.props.progress, 'seconds').format('hh:mm:ss', { trim: false });
        const formattedTimeOverall = moment.duration(this.props.timeOverall, 'hours').format('hh', { trim: false });
        const formattedTimeAlreadySpent = moment.duration(this.props.timeAlreadySpent, 'hours').format('hh', { trim: false });
        const percentageLoggedCurrently = Math.floor((formattedTimeAlreadySpent / formattedTimeOverall) * 100);
        let overviewBorderIndicatorColorClass = 'green';
        if (percentageLoggedCurrently > 60 && percentageLoggedCurrently < 80) {
            overviewBorderIndicatorColorClass = 'orange';
        }
        else if (percentageLoggedCurrently >= 80) { 
            overviewBorderIndicatorColorClass = 'red';
        }

        return (
            <div className="timer">
                <div className="time_current">{formattedProgress}</div>
                <div className={'logged-time-overview ' + overviewBorderIndicatorColorClass}>
                    <div className="time-overview-percentage inline">{percentageLoggedCurrently}%</div>
                    <div className="time-overview-text-divider inline seperator">:</div>
                    <div className="time_spent inline">{formattedTimeAlreadySpent}</div>
                    <div className="time-spent-over-time-overall-text inline seperator">/</div>
                    <div className="time_overall inline">{formattedTimeOverall}</div>
                    <div className="time-overall-time-text inline">hrs</div>
                </div>
            </div>
        );
    }
}

Timer.propTypes = {
    progress: PropTypes.number.isRequired,
    timeOverall: PropTypes.number.isRequired,
    timeAlreadySpent: PropTypes.number.isRequired
};

export default Timer;
