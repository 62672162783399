import { combineReducers } from 'redux';
import * as types from '../constants/actionTypes';

const initialEntryState = {
    entryID: 0,
    taskID: 0,
    taskName: '',
    accountID: 0,
    accountName: '',
    projectID: 0,
    projectName: '',
    entryStart: 0
};

function timerData(state = { entryToLog: null }, action) {
    switch (action.type) {
    case types.RECEIVE_STOP_ENTRY:
        if (action.data.entryToLog) {
            return { ...state, ...{ entryToLog: action.data.entryToLog } };
        }

        return { entryToLog: null };

    case types.SET_ENTRY_TO_LOG:
        return { ...state, ...{ entryToLog: action.entryToLog } };

    case types.RECEIVE_LOG_TIME:
    case types.SKIP_LOG_TIME:
        return { entryToLog: null };

    default:
        return state;
    }
}

function dayQueue(state = [], action) {
    switch (action.type) {
    case types.RECEIVE_UPDATE_DAY_QUEUE_WEIGHTS:
    case types.RECEIVE_DAY_QUEUE:
        return action.dayQueue;

    case types.RECEIVE_ADD_DAY_QUEUE_ITEM: {
        const newState = [
            ...state,
            action.item
        ];

        return newState;
    }
    case types.REQUEST_REMOVE_DAY_QUEUE_ITEM:
        return state.filter(item => {
            return item.itemID !== action.id;
        });

    case types.RECEIVE_REMOVE_DAY_QUEUE_ITEM:
        if (action.response.errors) {
            return action.originalState;
        }
        return state;

    default:
        return state;
    }
}

function currentEntry(state = initialEntryState, action) {
    switch (action.type) {
    case types.RECEIVE_CURRENT_ENTRY:
    case types.PERSIST_ENTRY:
    case types.RECEIVE_START_ENTRY:
    case types.RECEIVE_PERSIST_ENTRY:
    case types.NO_PERSIST_ENTRY:
        return { ...state, ...action.entry };

    case types.RECEIVE_STOP_ENTRY:
        return { ...initialEntryState };

    default:
        return state;
    }
}

function activityList(state = {
    withTask: [],
    noTask: [],
    day: 'today'
}, action ) {
    switch (action.type) {

    case types.RECEIVE_ACTIVITY_LIST:
        return { ...state, ...action.activityList, ...{ day: action.day }};
    case types.RECEIVE_LOG_TIME:
        return { ...state, ...action.activityList };

    case types.RECEIVE_STOP_ENTRY:
        if (action.data.activityList) {
            return { ...state, ...action.data.activityList };
        }

        return state;

    case types.RECEIVE_ADD_DAY_QUEUE_ITEM:
    case types.RECEIVE_REMOVE_DAY_QUEUE_ITEM: {
        let newState = { day: state.day };

        for (let category in { noTask: state.noTask, withTask: state.withTask }) {
            if (state.hasOwnProperty(category)) {

                newState[category] = state[category].map(item => {
                    if (item.taskID === parseInt(action.item.taskID, 10)) {
                        return Object.assign({}, item, { inDayQueue: (action.type === types.RECEIVE_ADD_DAY_QUEUE_ITEM) });
                    }

                    return { ...item };
                });
            }
        }

        return newState;
    }
    case types.REQUEST_REMOVE_TASKLESS_TIME:
        if (state.noTask.length) {
            const noTasks = state.noTask.filter(tasklessTime => {
                return tasklessTime.entryIds[0] !== action.entryIds[0];
            });

            return { ...state, ...{ noTask: noTasks }};
        }

        return state;

    case types.RECEIVE_REMOVE_TASKLESS_TIME:
        if (!action.result) {
        // If we failed to remove the time, return to the original state.
            return action.originalState;
        }
        
        return state;

    default:
        return state;
    }
}

const rootReducer = combineReducers({
    timerData,
    dayQueue,
    currentEntry,
    activityList
});

export default rootReducer;
