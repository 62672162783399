import React from 'react';
import PropTypes from 'prop-types';

var DayQueueAddButton = ({ addDayQueueItem }) => (
    <i className="add material-icons" onClick={addDayQueueItem}>add_circle_outline</i>
);

DayQueueAddButton.propTypes = {
    addDayQueueItem: PropTypes.func.isRequired
};

export default DayQueueAddButton;
