import React, { Component } from 'react';
import Cookie from 'universal-cookie';
const cookie = new Cookie();

class BgSwitcher extends Component {

    constructor(props) {
        super(props);

        this.state = {
            bg: this.getBgPreference()
        };

        this.handleChange = this.handleChange.bind(this);
    }

    // Removes classes starting with "bg--".
    removeBgClasses(classes) {
        return classes.split(' ').filter((i) => !(i.startsWith('bg--'))).join(' ');
    }

    handleChange(event) {
        this.setBg(event.target.value);

        this.setState({
            bg: event.target.value
        });

        this.setBgPreference(event.target.value);
    }

    getBgPreference() {
        let bg = cookie.get('bg');

        return bg === undefined ? 'none' : bg;
    }

    setBgPreference(bg) {
        cookie.set('bg', bg, {
            maxAge: 31557600,
            path: ''
        });

        return true;
    }

    setBg(bg) {
        if (bg !== 'none') {
            document.documentElement.className = this.removeBgClasses(document.documentElement.className);
            document.documentElement.classList.add('bg--' + bg);
        }
        else {
            document.documentElement.className = this.removeBgClasses(document.documentElement.className);
        }
    }

    componentDidMount() {
        this.setBg(this.state.bg);
    }

    render() {
        return (
            <div className="bg-switcher">
                <label>Background</label>
                <select onChange={this.handleChange} value={this.state.bg}>
                    <option value="none">None</option>
                    <option value="canvas">Canvas</option>
                    <option value="clock">Clock</option>
                    <option value="cosmic">Cosmic</option>
                    <option value="gears">Gears</option>
                    <option value="portal">Portal</option>
                    <option value="smoked">Smoked</option>
                    <option value="splash">Splash</option>
                    <option value="street">Street</option>
                    <option value="time-to-kill">Time to kill</option>
                    <option value="watercolour">Watercolour</option>
                </select>
            </div>
        );
    }
}

export default BgSwitcher;
