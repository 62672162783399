import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LogEntryForm extends Component {
    constructor(props) {
        super(props);
        this.handleLogClick = this.handleLogClick.bind(this);
        this.handleHoursChange = this.handleHoursChange.bind(this);
        this.handleMinutesChange = this.handleMinutesChange.bind(this);
        this.handleNotesChange = this.handleNotesChange.bind(this);
        this.handleTaskNameChange = this.handleTaskNameChange.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    handleLogClick(event) {
        this.props.actions.logTime(this.props.entry);
        event.preventDefault();
    }

    handleCancelClick(event) {
        this.props.actions.skipLogTime();
        event.preventDefault();
    }

    handleHoursChange(event) {
        var entryToLog = this.props.entry;
        var hours = event.target.value;

        if (!isNaN(hours)) {
            entryToLog.hours = event.target.value;
            this.props.actions.setEntryToLog(entryToLog);
        }
    }

    handleMinutesChange(event) {
        var entryToLog = this.props.entry;
        entryToLog.minutes = event.target.value;
        this.props.actions.setEntryToLog(entryToLog);
    }

    handleNotesChange(event) {
        var entryToLog = this.props.entry;
        entryToLog.notes = event.target.value;
        this.props.actions.setEntryToLog(entryToLog);
    }

    handleTaskNameChange(event) {
        var entryToLog = this.props.entry;
        entryToLog.taskToCreate = event.target.value;
        this.props.actions.setEntryToLog(entryToLog);
    }

    render() {
        var logButton = null, creationInput = null;

        if (this.props.entry.hours > 0 || this.props.entry.minutes > 0) {
            logButton = <a href="#" onClick={this.handleLogClick}>Log it</a>;
        }

        if (this.props.entry.taskToCreate) {
            creationInput =
                <span className="log-entry-form-task"><label>Name of task created:</label><input onChange={this.handleTaskNameChange} type="text" value={this.props.entry.taskToCreate}/></span>;
        }

        let cancelButton = <a href="#" className="btn-cancel" onClick={this.handleCancelClick}>Cancel</a>;

        return (
            <div className="log-entry-form">
                {creationInput}
                <label>Hours</label>
                <input type="text" value={this.props.entry.hours} onChange={this.handleHoursChange}/>
                <label>Minutes</label>
                <select type="text" value={this.props.entry.minutes} onChange={this.handleMinutesChange}>
                    <option value="0">0</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="45">45</option>
                </select>
                <label>Notes</label>
                <textarea value={this.props.entry.notes} onChange={this.handleNotesChange}></textarea>
                <div className="log-entry-form-actions">
                    {logButton}
                    {cancelButton}
                </div>
            </div>
        );
    }
}

LogEntryForm.propTypes = {
    entry: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
};

export default LogEntryForm;
