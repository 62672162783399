import * as types from '../constants/actionTypes';
import ApiService from '../services/apiService';

function receiveDayQueue(json) {
    return {
        type: types.RECEIVE_DAY_QUEUE,
        dayQueue: json
    };
}

export function getDayQueue() {
    return (dispatch) => {
        ApiService.makeRequest('/request/day-queue')
            .then(response => {
                dispatch(receiveDayQueue(response));
            });
    };
}

function requestAddDayQueueItem(taskID) {
    return {
        type: types.REQUEST_ADD_DAY_QUEUE_ITEM,
        taskID
    };
}

function receiveAddDayQueueItem(item) {
    return {
        type: types.RECEIVE_ADD_DAY_QUEUE_ITEM,
        item
    };
}

export function addDayQueueItem(taskID) {
    const data = {
        taskID: taskID
    };

    return (dispatch) => {
        dispatch(requestAddDayQueueItem(taskID));

        ApiService.makeRequest('/request/day-queue/add', data)
            .then(json => dispatch(receiveAddDayQueueItem(json)));
    };
}

function requestRemoveDayQueueItem(item) {
    return {
        type: types.REQUEST_REMOVE_DAY_QUEUE_ITEM,
        id: item.itemID
    };
}

function receiveRemoveDayQueueItem(response, item, originalState) {
    return {
        type: types.RECEIVE_REMOVE_DAY_QUEUE_ITEM,
        response,
        item,
        originalState
    };
}

export function removeDayQueueItem(item) {
    return (dispatch, getState) => {
    // Keep track of what the state was like before we removed the item, in case
    // there are errors with the AJAX request and we need to revert.
        const { dayQueue } = getState();

        dispatch(requestRemoveDayQueueItem(item));

        ApiService.makeRequest('/request/day-queue/remove', { itemID: item.itemID })
            .then(response => dispatch(receiveRemoveDayQueueItem(response, item, dayQueue)));
    };
}

function receiveUpdateDayQueueWeights(dayQueue) {
    return {
        type: types.RECEIVE_UPDATE_DAY_QUEUE_WEIGHTS,
        dayQueue
    };
}

function requestUpdateDayQueueWeight() {
    return {
        type: types.REQUEST_UPDATE_DAY_QUEUE_WEIGHTS
    };
}

export function updateDayQueueWeights(weights) {
    return dispatch => {
        dispatch(requestUpdateDayQueueWeight());

        ApiService.makeRequest('/request/day-queue/reorder', { order: weights })
            .then(response => dispatch(receiveUpdateDayQueueWeights(response)));
    };
}

