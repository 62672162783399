import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { startEntry } from '../actions/timeEntry';
import { getDayQueue, removeDayQueueItem, updateDayQueueWeights } from '../actions/dayQueue';
import DayQueueItem from '../components/DayQueueItem';
import {
    DragDropContext,
    Droppable,
    Draggable
} from 'react-beautiful-dnd';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',

    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // styles we need to apply on draggables
    ...draggableStyle,
});

class DayQueue extends Component {
    constructor(props) {
        super(props);

        this.handleRemoveDayQueueItem = this.handleRemoveDayQueueItem.bind(this);
        this.handleStartEntry = this.handleStartEntry.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);

        this.itemList = React.createRef();
        this.state = {
            dragDidEnd: false,
            items: []
        };
    }

    componentDidMount() {
        this.props.dispatch(getDayQueue());
    }

    static getDerivedStateFromProps(props, state) {
        return state.dragDidEnd ? {
            // smooths drag-and-drop by preserving the altered state
            dragDidEnd: false
        } : {
            items: props.items
        };
    }

    handleRemoveDayQueueItem(item) {
        this.props.dispatch(removeDayQueueItem(item));
    }

    handleStartEntry(entry) {
        this.props.dispatch(startEntry(entry));
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.items,
            result.source.index,
            result.destination.index
        );

        this.setState({
            dragDidEnd: true,
            items: items
        });

        this.props.dispatch(updateDayQueueWeights(items));
    }

    render() {
        return (
            <div id="day_queue" className="timer_section">
                <div className="title">
                    <i className="material-icons icon">wb_sunny</i>
                Day Queue
                </div>
                <div className="content">
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                >
                                    {this.state.items.map( (item, index) => (
                                        <Draggable 
                                            draggableId={item.itemID}
                                            key={item.itemID}
                                            index={index}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <DayQueueItem dragHandleProps={provided.dragHandleProps} startEntry={this.handleStartEntry} removeDayQueueItem={this.handleRemoveDayQueueItem} key={item.itemID} item={item}/>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>    
                </div>
            </div>
        );
    }
}

DayQueue.propTypes = {
    items: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        items: state.dayQueue,
    };
}

export default connect(mapStateToProps)(DayQueue);
