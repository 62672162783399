export const REQUEST_ACTIVITY_LIST = 'REQUEST_ACTIVITY_LIST';
export const RECEIVE_ACTIVITY_LIST = 'RECEIVE_ACTIVITY_LIST';
export const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST';

export const RECEIVE_CURRENT_ENTRY = 'RECEIVE_CURRENT_ENTRY';
export const GET_CURRENT_ENTRY = 'GET_CURRENT_ENTRY';
export const START_ENTRY = 'START_ENTRY';
export const STOP_ENTRY = 'STOP_ENTRY';
export const UPDATE_ENTRY = 'UPDATE_ENTRY';
export const PERSIST_ENTRY = 'PERSIST_ENTRY';
export const RECEIVE_PERSIST_ENTRY = 'RECEIVE_PERSIST_ENTRY';
export const RECEIVE_START_ENTRY = 'RECEIVE_START_ENTRY';
export const RECEIVE_STOP_ENTRY = 'RECEIVE_STOP_ENTRY';
export const NO_PERSIST_ENTRY = 'NO_PERSIST_ENTRY';
export const REQUEST_REMOVE_TASKLESS_TIME = 'REQUEST_REMOVE_TASKLESS_TIME';
export const RECEIVE_REMOVE_TASKLESS_TIME = 'RECEIVE_REMOVE_TASKLESS_TIME';

export const RECEIVE_DAY_QUEUE = 'RECEIVE_DAY_QUEUE';
export const GET_DAY_QUEUE = 'GET_DAY_QUEUE';
export const RECEIVE_ADD_DAY_QUEUE_ITEM = 'RECEIVE_ADD_DAY_QUEUE_ITEM';
export const REQUEST_ADD_DAY_QUEUE_ITEM = 'REQUEST_ADD_DAY_QUEUE_ITEM';
export const REQUEST_REMOVE_DAY_QUEUE_ITEM = 'REQUEST_REMOVE_DAY_QUEUE_ITEM';
export const RECEIVE_REMOVE_DAY_QUEUE_ITEM = 'RECEIVE_REMOVE_DAY_QUEUE_ITEM';
export const RECEIVE_UPDATE_DAY_QUEUE_WEIGHTS = 'RECEIVE_UPDATE_DAY_QUEUE_WEIGHTS';
export const REQUEST_UPDATE_DAY_QUEUE_WEIGHTS = 'REQUEST_UPDATE_DAY_QUEUE_WEIGHTS';

export const SET_ENTRY_TO_LOG = 'SET_ENTRY_TO_LOG';
export const RECEIVE_LOG_TIME = 'RECEIVE_LOG_TIME';
export const SKIP_LOG_TIME = 'SKIP_LOG_TIME';
export const LOG_TIME = 'LOG_TIME';
